<template>

    <!--    <board-write-component :boardType="'commuUpdate'"></board-write-component>-->
    <div class="commu write">
        <div class="top">
            <div class="t-header">
                <div class="title">
                    꿈, 걱정, 고민 무엇이든 멘토에게 물어보세요!<br>
                    <span>아빠미소멘토단</span>
                </div>
            </div>
        </div>
        <div class="form">
            <div class="title">게시글 작성</div>내 글을 꼭 봐주길 원하는 멘토를 선택하세요
            <board-write-component :boardType="'commuUpdate'" :boardKey="boardKey"></board-write-component>
        </div>
        <div class="btn-wrap">
            <button class="write_btn full_btn mb15" @click="save">작성</button>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import BoardWriteComponent from "@/components/board/BoardWriteComponent";
    import EventBus from "@/utils/event-bus";
    export default {
        name: 'CommunityUpdate',
        mixins: [],
        components: {
            BoardWriteComponent
        },
        props: {
        },
        data() {
            return{
                boardKey : this.$route.params.wr_id,
            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
            save() {
                EventBus.$emit('writeBoard');
            },

        },
        watch: {

        },
    }
</script>
