<template>
    <div>
<!--        <page-header :headerTitle="'배너 상세'"></page-header>-->
        <div>
            <banner-detail-component :bannerKey="bannerKey"></banner-detail-component>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import BannerDetailComponent from "@/components/banner/BannerDetailComponent";
    import EventBus from "@/utils/event-bus";
    export default {
        name: 'BannerDetail',
        mixins: [],
        provide() {
            return{
            }
        },
        components: {
            BannerDetailComponent
        },
        props: {
        },
        data() {
            return{
                bannerKey: this.$route.params.p_idx,
            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
        },
        watch: {

        },
    }
</script>
