<template>
    <div>
        <board-list-component :boardType="'letterSearch'"></board-list-component>
    </div>
</template>

<script>
    // @ is an alias to /src
    import BoardListComponent from "@/components/board/BoardListComponent";

    export default {
        name: 'LetterSearch',
        mixins: [],
        components: {
            BoardListComponent
        },
        props: {},
        data() {
            return {}
        },
        beforeRouterEnter() {
        },
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {},
        watch: {},
    }
</script>
