<template>
    <div>
        <regist-component :step="step" :parentsChangeStep="changeStep"></regist-component>
        <terms-modal :termsType="termsType"></terms-modal>
    </div>
</template>

<script>
    // @ is an alias to /src
    import RegistComponent from "@/components/auth/RegistComponent";
    import TermsModal from "@/components/modal/TermsModal";
    import EventBus from "@/utils/event-bus";
    export default {
        name: 'Regist',
        mixins: [],
        components: {
            RegistComponent,
            TermsModal
        },
        props: {
        },
        data() {
            return{
                step: 1,
                termsType: '',
            }
        },
        beforeRouterEnter() {},
        created() {
            EventBus.$on('changeStep', this.changeStep);
            EventBus.$on('openTermsModal', this.openTermsModal);
        },
        mounted() {

        },
        beforeDestroy() {
            EventBus.$off('changeStep');
            EventBus.$off('openTermsModal');
        },
        destroyed() {},
        computed: {
        },
        methods:{
            changeStep(step) {
                this.step = step;
            },
            backEvt() {
                if(this.step > 1 && this.step < 5) {
                    EventBus.$emit('beforeStep');
                    return false;
                }
                this.$root.$children[0].hasBack = true;
                this.$router.back();
            },
            test() {
                EventBus.$emit('beforeStep');
            },
            openTermsModal(type) {
                this.termsType = type;
                this.$modal.show('terms-modal');
            }
        },
        watch: {

        },
    }
</script>
