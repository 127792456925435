<template>
    <div>
        <page-header slot="fixed" :headerMenu="true" :headerBack="true"></page-header>
        <ion-content >
            LetterUpdate
        </ion-content>
    </div>
</template>

<script>
    // @ is an alias to /src
    export default {
        name: 'LetterUpdate',
        mixins: [],
        components: {
        },
        props: {},
        data() {
            return {
                boardKey: this.$route.params.wr_id,
            }
        },
        beforeRouterEnter() {
        },
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {},
        watch: {},
    }
</script>
