<template>
    <div class="commu write">
        <div class="top">
            <div class="t-header">
                <div class="title">
                    꿈, 걱정, 고민 무엇이든 멘토에게 물어보세요!<br>
                    <span>아빠미소멘토단</span>
                </div>
            </div>
        </div>
        <div class="form">
            <div class="title">게시글 작성</div>내 글을 꼭 봐주길 원하는 멘토를 선택하세요
            <board-write-component :boardType="'commuWrite'"></board-write-component>
        </div>
        <div class="btn-wrap">
            <button class="write_btn full_btn mb15" @click="save">작성</button>
        </div>
        <mentor-sel-modal :mentorList="mentorList"></mentor-sel-modal>
    </div>
</template>
<script>
    // @ is an alias to /src
    import BoardWriteComponent from "@/components/board/BoardWriteComponent";
    import MentorSelModal from "@/components/modal/MentorSelModal";
    import EventBus from "@/utils/event-bus";
    export default {
        name: 'CommunityWrite',
        mixins: [],
        components: {
            BoardWriteComponent,
            MentorSelModal
        },
        props: {
        },
        data() {
            return{
                mentorList: [],
            }
        },
        beforeRouterEnter() {},
        created() {
            EventBus.$on('openMentorSelModal', this.openSelModal)
        },
        mounted() {

        },
        beforeDestroy() {
            EventBus.$off('openMentorSelModal')
        },
        destroyed() {},
        computed: {
        },
        methods:{
            openSelModal(mentorList) {
                this.mentorList = mentorList;
                this.$nextTick(() => {
                    this.$modal.show('mentor-sel-modal')
                })
            },
            save() {
                EventBus.$emit('writeBoard');
            },
        },
        watch: {

        },
    }
</script>
