<template>
    <div>
        <board-detail-component :boardType="'noticeDetail'" :boardKey="boardKey"></board-detail-component>
    </div>
</template>

<script>
    // @ is an alias to /src
    import BoardDetailComponent from "@/components/board/BoardDetailComponent";
    export default {
        name: 'NoticeDetail',
        mixins: [],
        components: {
            BoardDetailComponent
        },
        props: {
        },
        data() {
            return{
                boardKey: this.$route.params.wr_id,
            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{

        },
        watch: {

        },
    }
</script>
