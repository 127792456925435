<template>
    <div class="wrap">
        <div class="main_bg change_bg_img">
            <div class="main_content">
                <div class="main_logo" id="main_logo">
                    <div class="logo_ami"><img src="@/assets/image/rebuild/ami_logo.png" alt=""></div>
<!--                    <div class="logo_lotte"><img src="@/assets/image/rebuild/lotte_logo.png" alt=""></div>-->
                </div>
            </div>
        </div>
        <div class="ami_box">아미단?</div>
        <div class="main_section1 main_content">
            <div class="section1_part1">
                <div class="description_text">
                    아미단은 <strong>아이들의 미래와 꿈을 응원하는 아빠미소멘토단</strong>입니다. 아미단은 아이들의 물음에 언제 어디서나 응답할 준비를 하고 있어요.<br/>
                    그래서 아미단은 '응응단'이란 별칭도 있답니다. 아이들을 '응원하고' 언제든 '응답하는' 멘토단이란 뜻이죠.<br/>
                    아미단은 풍부한 경험과 따뜻한 '아빠미소'를 장착한 멘토들로 운영됩니다. 소중한 마음들이 모여 만들어진 사회적 자산(social capital),<br/>
                    눈에 보이지 않는 그 자산이 아이들의 마음속에 들어가 '꿈을 키우는 힘'이 되길 바랍니다
                </div>
            </div>
            <div class="section1_title"></div>
            <div class="section1_part2">
                <div class="dream_act dream_cookie">
                    <div class="dream_act_innerBox">
                        <div class="dream_act_icon"></div>
                        <div class="dream_act_title">과자로 꿈짓기</div>
                        <div class="dream_act_content">‘너의 꿈을 맛있게 표현해보렴’
                        </div>
                    </div>
                    <div class="dream_pic"></div>
                </div>
                <div class="dream_act dream_letter">
                    <div class="dream_act_innerBox">
                        <div class="dream_act_icon"></div>
                        <div class="dream_act_title">꿈편지</div>
                        <div class="dream_act_content">‘너의 꿈을 말해줘’ <br>
                            아빠미소멘토단에게 전하는 <br>
                            아이들의 꿈이야기
                        </div>
                    </div>
                    <div class="dream_pic"></div>
                </div>
                <div class="dream_act dream_camp">
                    <div class="dream_act_innerBox">
                        <div class="dream_act_icon"></div>
                        <div class="dream_act_title">꿈캠프</div>
                        <div class="dream_act_content">‘아빠미소멘토단과 함께하는 꿈캠프’
                        </div>
                    </div>
                    <div class="dream_pic"></div>
                </div>

            </div>
        </div>
        <a name="slide_tar"></a>
        <member-slide-component></member-slide-component>

        <div class="main_section3_bg">
            <div class="main_section3 main_content">
                <div class="s3_obj" id="s3_obj"><img src="@/assets/image/rebuild/object_1.png" alt=""></div>
                <div class="section3_title"></div>
                <div class="s3_box">
                    <div class="s3_box_txt1">아이 하나 키우는데 온 마을이 필요하다 - 아프리카 속담</div>
                    <div class="s3_box_txt2">혼자서 이룰 수 있는 꿈은 많지 않습니다. <br>
                        아이들이 갖는 꿈에 대한 여러가지 고민을 ‘아빠미소멘토단’이 되어<br>
                        아이들의 꿈을 찾는 여정에 함께해 주세요
                    </div>

                    <button class="login_btn" @click="movePage('/auth')">
                        {{hasLogin() ? '메인으로가기' : '로그인하기'}}
                    </button>
                </div>
            </div>


        </div>

    </div>

</template>

<script>
    // @ is an alias to /src
    import VuxAlert from '@/mixins/vux-alert';
    import {mapGetters, mapState} from "vuex";
    import Member from "./member/Member";
    import MemberSlideComponent from "@/components/member/MemberSlideComponent";

    export default {
        name: 'Info',
        mixins: [VuxAlert],
        created() {},
        mounted() {
            this.mt = new URLSearchParams(location.search).get("mt");
            if( this.mt == 1 ){
                this.moveList();
            }
        },
        destroyed() {


        },
        components: {Member, MemberSlideComponent},
        directives: {},
        data() {
            return {
                tabIdx: 1,
                mt:0,
            }
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            }),
        },
        methods: {

            hasLogin() {
                return this.UserInfo.hasOwnProperty('mb_no');
            },
            movePage() {
                if(this.hasLogin()) {
                    // this.$router.back();
                    this.$router.push('/');
                }else{
                    this.$router.push('/auth');
                }
            },
            moveList() {
                document.location.href="#slide_tar";
                // $("html,body").animate({
                //     scrollTop: $(".main_section2_bg").offset().top
                // }, 500);
            }
        }
    }
</script>
