<template>
    <div>
    <board-detail-component :boardKey="boardKey" :boardType="'commuDetail'"></board-detail-component>
    </div>

</template>

<script>
    // @ is an alias to /src
    import BoardDetailComponent from "@/components/board/BoardDetailComponent";

    export default {
        name: 'CommunityDetail',
        mixins: [],
        provide() {
            return{
            }
        },
        components: {
            BoardDetailComponent,
        },
        props: {
        },
        data() {
            return{
                boardKey: this.$route.params.wr_id,
                imageUrl: '',

            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
        },
        watch: {

        },
    }
</script>
