<template>
<!--    <board-list-component :boardType="'myMemteeCommu'"></board-list-component>-->

    <div class="menu-wrap">
        <div class="title">내 활동내역</div>
        <div class="menu">
            <button class="mypage_tab" @click="changeTab('myCommentLetter')" :class="{'active' : tab === 'myCommentLetter'}">꿈편지 댓글</button>
            <button class="mypage_tab" @click="changeTab('myLikeLetter')" :class="{'active' : tab === 'myLikeLetter'}">꿈편지 추천</button>
            <button class="mypage_tab" @click="changeTab('myMemteeCommu')" :class="{'active' : tab === 'myMemteeCommu'}">소통 작성</button>
            <button class="mypage_tab" @click="changeTab('myLikeCommu')" :class="{'active' : tab === 'myLikeCommu'}">소통 추천</button>

        </div>
        <board-list-component :boardType="'myCommentLetter'" v-if="tab === 'myCommentLetter'" class="letter"></board-list-component>
        <board-list-component :boardType="'myLikeLetter'" v-else-if="tab === 'myLikeLetter'" class="letter"></board-list-component>
        <board-list-component :boardType="'myMemteeCommu'" v-else-if="tab === 'myMemteeCommu'" class="commu"></board-list-component>
        <board-list-component :boardType="'myLikeCommu'" v-else-if="tab === 'myLikeCommu'" class="commu"></board-list-component>
    </div>
</template>

<script>
    // @ is an alias to /src
    import BoardListComponent from "@/components/board/BoardListComponent";
    import {mapState, mapGetters} from "vuex";
    export default {
        name: 'MenteeActivity',
        mixins: [],
        components: {
            BoardListComponent
        },
        props: {
        },
        data() {
            return{
                // tab: 'myMemteeCommu'

                tab: this.$route.params.tab ? this.$route.params.tab : 'myCommentLetter',
            }
        },
        beforeRouterEnter() {},
        created() {
            if(this.$route.params.tab != this.tab) {
                this.$router.replace(`/mypage/${this.UserInfo.mb_no}/${this.tab}`);
            }
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })
        },
        methods:{
            changeTab(name) {
                this.tab = '';
                this.$nextTick(() => {
                    this.tab = name;
                    this.$router.replace(`/mypage/${this.UserInfo.mb_no}/${this.tab}`);
                })
            },
        },
        watch: {

        },
    }
</script>
