<template>
    <div class="notice service mw1260">
        <board-list-component :boardType="'notice'"></board-list-component>
        <notice-detail-modal :boardKey="boardKey"></notice-detail-modal>
    </div>

</template>

<script>
    // @ is an alias to /src
    import BoardListComponent from "@/components/board/BoardListComponent";
    import NoticeDetailModal from "@/components/modal/NoticeDetailModal";
    import EventBus from "@/utils/event-bus";

    export default {
        name: 'Notice',
        mixins: [],
        components: {
            BoardListComponent, NoticeDetailModal
        },
        props: {},
        data() {
            return {
                boardKey: 0,
            }
        },
        beforeRouterEnter() {
        },
        created() {
            EventBus.$on('boardGoDetail', this.openModal);
        },
        mounted() {

        },
        beforeDestroy() {
            EventBus.$off('boardGoDetail');
        },
        destroyed() {
        },
        computed: {},
        methods: {
            openModal(key) {
                this.boardKey = key;
                this.$nextTick(() => {
                    this.$modal.show('notice-detail-modal')
                })
            },
        },
        watch: {},
    }
</script>
