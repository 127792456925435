<template>
    <div class="mypage">
        <div class="info-wrap">
            <div class="profile" :style="`background-image: url(${returnProfileImage()}); background-size:contain`">
                <!--                <img src="@/assets/image/rebuild/default.png">-->
            </div>
            <div class="info">
                <div class="name">{{this.UserInfo.mb_name}}</div>
                <div class="id">{{this.UserInfo.mb_email}}</div>
                <button class="info-btn" @click="openPass()" >내 정보수정</button>
            </div>
        </div>
        <!--<div class="menu-wrap">
            <div class="title">내 활동내역</div>
            <div class="menu">
                <button class="mypage_tab" @click="changeTab('myCommentLetter')" :class="{'active' : tab === 'myCommentLetter'}">꿈편지</button>
                <button class="mypage_tab" @click="changeTab('myLikeLetter')" :class="{'active' : tab === 'myLikeLetter'}">꿈편지추천</button>
                <button class="mypage_tab" @click="changeTab('myCommentCommu')" :class="{'active' : tab === 'myCommentCommu'}">소통</button>
                <button class="mypage_tab" @click="changeTab('myLikeCommu')" :class="{'active' : tab === 'myLikeCommu'}">소통 추천</button>
            </div>
            <board-list-component :boardType="'myCommentLetter'" v-if="tab === 'myCommentLetter'" class="letter"></board-list-component>
            <board-list-component :boardType="'myLikeLetter'" v-else-if="tab === 'myLikeLetter'" class="letter"></board-list-component>
            <board-list-component :boardType="'myCommentCommu'" v-if="tab === 'myCommentCommu'" class="commu"></board-list-component>
            <board-list-component :boardType="'myLikeCommu'" v-else-if="tab === 'myLikeCommu'" class="commu"></board-list-component>
        </div>-->
        <component :is="ActivityComponent" v-if="ActivityComponent"/>
        <update-pw-modal></update-pw-modal>
        <update-phone-modal></update-phone-modal>
    </div>
</template>

<script>
    // @ is an alias to /src
    import BoardListComponent from "@/components/board/BoardListComponent";
    import UpdatePwModal from "@/components/modal/UpdatePwModal";
    import UpdatePhoneModal from "@/components/modal/UpdatePhoneModal";
    import VuxAlert from "@/mixins/vux-alert";
    import {mapState} from "vuex";
    import imageOption from "@/mixins/imageOption";

    export default {
        name: 'MyPage',
        mixins: [VuxAlert,imageOption],
        components: {
            BoardListComponent,
            UpdatePwModal,
            UpdatePhoneModal
        },
        props: {
        },
        data() {
            return{
                type: 1, //0: 관리자, 1: 멘토, 2: 멘티, 3: 센터
            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            ...mapState({
                UserInfo : state => state.userInfo.UserInfo

            }),

            ActivityComponent() {
                if (this.UserInfo.mb_type === 1) {
                    return () => import(`@/views/activity/MentorActivity`)
                } else if (this.UserInfo.mb_type === 2) {
                    return () => import(`@/views/activity/MenteeActivity`)
                } else {
                    return null;
                }
            }
        },
        methods:{

            changeTab(name) {
                this.tab = '';
                this.$nextTick(() => {
                    this.tab = name;
                })
            },
            openConfirm() {
                this.createConfirm({
                    title: '',
                    content: '정말 삭제하시겠습니까?',
                    confirmText: '확인',
                    cancelText: '취소'
                })
            },
            openAlert() {
                this.createAlert({
                    title: '',
                    content: '저장되었습니다.',
                    btnText: '확인',
                })
            },
            openModal(name) {
                this.$modal.show(name);
            },
            openPass() {
                this.passwordChkAlert({
                    checkPassword: this.checkPassword,
                    confirm: this.moveProfile,
                })
            },
            checkPassword(password) {
                let params = {
                    log_type: 2,
                    mb_id : this.UserInfo.mb_id,
                    mb_password: password,
                }
                return this.$api.$auth.login(params).then(res => res.Data.Result).then(res => {
                    return res.Info.type === 1;
                })
            },
            returnProfileImage() {
                return this.UserInfo.hasOwnProperty('Profile') && this.UserInfo.Profile.length > 0 ? `${this.UserInfo.Profile[0].org_url}?${this.setImageOptions(122, 122, 'png')}` : ''
            },
            moveProfile() {
                this.$router.push(`/mypage/${this.UserInfo.mb_no}/profile`)
            },
        },
        watch: {

        },
    }
</script>
