<template>
    <div class="wrap">
        <div class="commu">
            <board-list-component :boardType="'commu'"></board-list-component>
        </div>
    </div>

</template>

<script>
    // @ is an alias to /src
    import BoardListComponent from "@/components/board/BoardListComponent";
    export default {
        name: 'Community',
        mixins: [],
        components: {
            BoardListComponent
        },
        props: {
        },
        data() {
            return{

            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{

        },
        watch: {

        },
    }
</script>
