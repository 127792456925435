<template>
    <div class="j_content step4">
        <div class="title">회원가입 완료</div>
        <span>{{UserInfo.mb_name}}</span>님 회원가입을 환영합니다! <br>
        관리자 가입승인 후 이용하실 수 있습니다!
    </div>
</template>

<script>
    // @ is an alias to /src
    import {mapGetters, mapState} from "vuex";
    export default {
        name: 'ReadyAuth',
        mixins: [],
        components: {},
        props: {},
        data() {
            return {}
        },
        beforeRouterEnter() {
        },
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })
        },
        methods: {},
        watch: {},
    }
</script>
