<template>
    <div>
        <component :is="sideComponent" v-if="sideComponent"  />
        <div class="mw1260">
            <alarm-component :alarmType="'list'"></alarm-component>
        </div>
    </div>

</template>

<script>
    // @ is an alias to /src
    import AlarmComponent from "@/components/alarm/AlarmComponent";
    import {initAlarmHasGet, getAlarmListFunc} from "@/mixins/alarmMixins";

    export default {
        name: 'Alarm',
        mixins: [],
        components: {
            AlarmComponent
        },
        props: {
        },
        data() {
            return{
                type: 1, //0: 관리자, 1: 멘토, 2: 멘티, 3: 센터
            }
        },
        beforeRouterEnter() {},
        created() {

            initAlarmHasGet();
            this.$nextTick(() => {
                getAlarmListFunc();
            });
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {

        },
        methods:{

        },
        watch: {

        },
    }
</script>
