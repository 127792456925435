<template>
    <div class="leave">
        <div class="title">회원탈퇴</div>
        <div class="terms">
            <div class="title">회원탈퇴약관</div>
            <terms-component :termsType="'leave'"></terms-component>
            <!--<div class="content">
                회원탈퇴를 하실 경우 회원님의 모든 정보가 삭제 되니 신중하게 결정하셔서 신청해 주세요.
                다만「개인정보보호법」및「전자상거래 등에서 소비자 보호에 관한 법률」에 따라 귀하의 거래정보는 법에서 정해진 기간까지 별도로 보관될 수 있습니다.
                회원탈퇴 완료 후 쿠폰은 모두 삭제됩니다.
                회원탈퇴 신청 즉시 회원전용 서비스의 이용이 불가능합니다.
                회원 탈퇴 후 재가입 시에는 신규 회원 가입으로 처리되며, 탈퇴전의 회원정보와 거래정보 및 쿠폰 정보 등은 복구되지 않습니다.
                회원탈퇴 이후에는 게시물 편집, 삭제가 불가능하므로, 게시물을 편집하거나 삭제하기 바라시는 경우 게시물 편집/삭제 후 회원탈퇴를 해 주시기
            </div>-->
            <div class="checks">
                <input type="checkbox" id="agree" @change="checkAgree($event.target.checked)"></input>
                <label for="agree">약관에 동의합니다</label>
            </div>
            <div class="wrong_form_msg_quit mt5" v-if="validation.hasError('agree')"
                 v-html="validation.firstError('agree')"></div>
        </div>
        <!--<div class="form">
            <div class="title-wrap">
                <div class="title">탈퇴 사유</div>
                <div class="sub-title">해당되는 사유를 선택해주세요</div>
            </div>
            <div class="value-wrap">
                <div class="value">
                    <div class="checks">
                        <input type="checkbox" id="value1"></input>
                        <label for="value1">약관에 동의합니다</label>
                    </div>
                </div>
                <div class="value">
                    <div class="checks">
                        <input type="checkbox" id="value2"></input>
                        <label for="value2">약관에 동의합니다</label>
                    </div>
                </div>
                <div class="value">
                    <div class="checks">
                        <input type="checkbox" id="value3"></input>
                        <label for="value3">약관에 동의합니다</label>
                    </div>
                </div>
                <div class="value">
                    <div class="checks">
                        <input type="checkbox" id="value4"></input>
                        <label for="value4">기타(직접입력)</label>
                    </div>
                    <textarea class="extra" placeholder="기타 사유를 입력해주세요 (100자 이내)"></textarea>
                </div>
            </div>
            <div class="title-wrap">
                <div class="title">비밀번호</div>
                <div class="sub-title">본인 확인을 위해 현재 비밀번호를 입력하세요</div>
            </div>
            <input type="password" placeholder="현재 비밀번호 입력"></input>
        </div>-->
        <quit-component></quit-component>
        <div class="button-wrap">
            <button class="full_btn leave_btn" @click="clickBtn()">회원탈퇴</button>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import TermsComponent from "@/components/terms/TermsComponent";
    import QuitComponent from "../../components/quit/QuitComponent";
    import EventBus from "@/utils/event-bus";
    import MemberValidator from "@/mixins/validators/MemberValidator";
    export default {
        name: 'Quit',
        mixins: [MemberValidator],
        components: {
            TermsComponent,
            QuitComponent
        },
        props: {
        },
        data() {
            return{

            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
            clickBtn() {
                this.$validate(['agree']).then(success => {
                    if (!success) {
                        return false;
                    }
                    EventBus.$emit('quitMemberFunc')
                })
            },
            checkAgree(value) {
                if (value) {
                    return this.agree = 1
                }
                this.agree = ''

            }
        },
        watch: {

        },
    }
</script>
