<template>
    <div>
        <!--<div class="popup_wrap">
            <div class="popup">
                <div class="popup_img">
                    <div class="popup_close"></div>
                </div>
                <div class="popup_text">하루동안 보지않기</div>
            </div>
            <div class="popup">
                <div class="popup_close"></div>
                <div class="popup_img"></div>
                <div class="popup_text">하루동안 보지않기</div>
            </div>
        </div>-->
        <div class="wrap">
            <div class="letter">
                <board-list-component :boardType="'letter'"></board-list-component>
            </div>
            <div>
                <button class="to_top"></button>
            </div>
        </div>
        <transition :name="`detail-${$root.$children[0].transitionName}`">
            <router-view></router-view>
        </transition>

    </div>
</template>

<script>
    // @ is an alias to /src
    import BoardListComponent from "@/components/board/BoardListComponent";

    export default {
        name: 'Letter',
        mixins: [],
        components: {
            BoardListComponent
        },
        props: {},
        data() {
            return {}
        },
        beforeRouterEnter() {
        },
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {},
        watch: {},
    }
</script>
