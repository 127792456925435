<template>
    <div>
        <login-component></login-component>
    </div>
</template>

<script>
    // @ is an alias to /src
    import LoginComponent from "@/components/auth/LoginComponent";
    export default {
        name: 'Login',
        mixins: [],
        components: {
            LoginComponent
        },
        props: {
        },
        data() {
            return{

            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
        },
        watch: {

        },
    }
</script>
