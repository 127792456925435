<template>

    <div>

        <div>
            <div class="main_bg">
                <div class="main_image mentee"></div>
                <div class="mbc_wrap">
                    <div class="main_bg_content">
                        <div class="home_logo"></div>
                        <span class="home_subtitle">우리 아이들의 꿈을 응원하는 아빠미소멘토단</span>
                    </div>
                </div>
                <member-list-component :memberType="'adminHome'"></member-list-component>
            </div>
        </div>
        <div>
            <button class="to_top"></button>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import MemberListComponent from "@/components/member/MemberListComponent";
    export default {
        name: 'Member',
        mixins: [],
        components: {
            MemberListComponent
        },
        props: {
        },
        data() {
            return{

            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{

        },
        watch: {

        },
    }
</script>
