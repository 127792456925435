<template>
    <div>
        <banner-component></banner-component>
        <component :is="component" v-if="component"  />
    </div>
</template>

<script>
    import BannerComponent from "@/components/banner/BannerComponent";
    import {mapState, mapGetters} from "vuex";
    export default {
        name: 'Home',
        mixins: [],
        components: {
            BannerComponent
        },
        props: {},
        data() {
            return {
                type: 1, //0: 관리자, 1: 멘토, 2: 멘티, 3: 센터
            }
        },
        beforeRouterEnter() {
        },
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            }),
            component() {
                if(this.UserInfo.mb_type === 0) {
                    return () => import(`@/views/letter/Letter`)
                }else if(this.UserInfo.mb_type === 1) {
                    return () => import(`@/views/letter/Letter`)
                }else if(this.UserInfo.mb_type === 2) {
                    // return () => import(`@/views/commu/Community`)
                    return () => import(`@/views/letter/Letter`)
                }else if(this.UserInfo.mb_type === 3) {
                    return () => import(`@/views/letter/Letter`)
                }else{
                    return null;
                }
            }
        },
        methods: {},
        watch: {},
    }
</script>
