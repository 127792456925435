<template>
    <div class="faq service mw1260">
        <board-list-component :boardType="'faq'"></board-list-component>
    </div>
</template>

<script>
    // @ is an alias to /src
    import BoardListComponent from "@/components/board/BoardListComponent";
    export default {
        name: 'Faq',
        mixins: [],
        components: {
            BoardListComponent
        },
        props: {
        },
        data() {
            return{

            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{

        },
        watch: {

        },
    }
</script>
