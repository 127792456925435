<template>
    <div class="menu-wrap">
        <div class="title">내 활동내역</div>
        <div class="menu">
            <button class="mypage_tab" @click="changeTab('myCommentLetter')" :class="{'active' : tab === 'myCommentLetter'}">꿈편지</button>
            <button class="mypage_tab" @click="changeTab('myLikeLetter')" :class="{'active' : tab === 'myLikeLetter'}">꿈편지추천</button>
            <button class="mypage_tab" @click="changeTab('myCommentCommu')" :class="{'active' : tab === 'myCommentCommu'}">멘토&멘티 댓글</button>
            <button class="mypage_tab" @click="changeTab('myLikeCommu')" :class="{'active' : tab === 'myLikeCommu'}">멘토&멘티 추천</button>
            <button class="mypage_tab" @click="changeTab('myPostMentor')" :class="{'active' : tab === 'myPostMentor'}">멘토끼리 작성</button>
            <button class="mypage_tab" @click="changeTab('myCommentMentor')" :class="{'active' : tab === 'myCommentMentor'}">멘토끼리 댓글</button>
            <button class="mypage_tab" @click="changeTab('myLikeMentor')" :class="{'active' : tab === 'myLikeMentor'}">멘토끼리 추천</button>
        </div>
        <board-list-component :boardType="'myCommentLetter'" v-if="tab === 'myCommentLetter'" class="letter"></board-list-component>
        <board-list-component :boardType="'myLikeLetter'" v-else-if="tab === 'myLikeLetter'" class="letter"></board-list-component>
        <board-list-component :boardType="'myCommentCommu'" v-if="tab === 'myCommentCommu'" class="commu"></board-list-component>
        <board-list-component :boardType="'myLikeCommu'" v-else-if="tab === 'myLikeCommu'" class="commu"></board-list-component>
        <board-list-component :boardType="'myPostMentor'" v-else-if="tab === 'myPostMentor'" class="mentor"></board-list-component>
        <board-list-component :boardType="'myCommentMentor'" v-else-if="tab === 'myCommentMentor'" class="mentor"></board-list-component>
        <board-list-component :boardType="'myLikeMentor'" v-else-if="tab === 'myLikeMentor'" class="mentor"></board-list-component>
    </div>
</template>

<script>
    // @ is an alias to /src
    import BoardListComponent from "@/components/board/BoardListComponent";
    import {mapState, mapGetters} from "vuex";
    export default {
        name: 'MentorActivity',
        mixins: [],
        components: {
            BoardListComponent
        },
        props: {
        },
        data() {
            return{
                // tab: 'myCommentLetter',
                tab: this.$route.params.tab ? this.$route.params.tab : 'myCommentLetter',
            }
        },
        beforeRouterEnter() {},
        created() {
            if(this.$route.params.tab != this.tab) {
                this.$router.replace(`/mypage/${this.UserInfo.mb_no}/${this.tab}`);
            }
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })
        },
        methods:{
            changeTab(name) {
                this.tab = '';
                this.$nextTick(() => {
                    this.tab = name;
                    this.$router.replace(`/mypage/${this.UserInfo.mb_no}/${this.tab}`);
                })
            },
        },
        watch: {

        },
    }
</script>
