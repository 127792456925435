<template>
    <div class="wrap">
        <alarm-component :alarmType="'detail'"></alarm-component>
    </div>
</template>

<script>
    // @ is an alias to /src
    import AlarmComponent from "@/components/alarm/AlarmComponent";
    import {getAlarmListFunc} from "@/mixins/alarmMixins";

    export default {
        name: 'AlarmDetail',
        mixins: [],
        components: {
            AlarmComponent

        },
        props: {
        },
        data() {
            return{

            }
        },
        beforeRouterEnter() {},
        created() {
            getAlarmListFunc();
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
            
        },
        watch: {

        },
    }
</script>
