<template>
    <div class="service terms mw1260">
        <div class="top">
            <div class="t-header">
                <div class="title">
                    아빠미소멘토단 약관은 다음과 같은 내용을 담고 있습니다.<br>
                    <span>약관</span>
                </div>
            </div>
        </div>
        <div class="term-wrap">
            <div :value="termsType" class="tab">
                <button value="stipulation"  @click="changeTerms('stipulation')" :class="{'active' : termsType === 'stipulation'}"> <!--선택한 약관에 active 추가-->
                    서비스이용약관
                </button>
                <button value="privacy"  @click="changeTerms('privacy')" :class="{'active' : termsType === 'privacy'}" >
                    개인정보처리방침
                </button>
            </div>
            <terms-component :termsType="termsType"></terms-component>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import TermsComponent from "@/components/terms/TermsComponent";
    import EventBus from "@/utils/event-bus";

    export default {
        name: 'Terms',
        mixins: [],
        components: {
            TermsComponent
        },
        props: {
        },
        data() {
            return{
                termsType: 'stipulation'
            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {

        },
        destroyed() {},
        computed: {
        },
        methods:{
            changeTerms(type) {
                this.termsType = type;
            },
        },
        watch: {

        },
    }
</script>
