<template>
        <div>
            <board-detail-component :boardKey="boardKey" :boardType="'mentorDetail'"></board-detail-component>
        </div>
</template>

<script>
    // @ is an alias to /src
    import BoardDetailComponent from "@/components/board/BoardDetailComponent";
    import CommentModifyModal from "@/components/modal/CommentModifyModal";
    import ImageDetailModal from "@/components/modal/ImageDetailModal";
    import EventBus from "@/utils/event-bus";
    export default {
        name: 'MentorCommunityDetail',
        mixins: [],
        provide() {
            return{
                openImageInfoModal: this.openImageInfoModal,
            }
        },
        components: {
            BoardDetailComponent,
            CommentModifyModal,
            ImageDetailModal
        },
        props: {
        },
        data() {
            return{
                boardKey: this.$route.params.wr_id,
                commentData: {},
                imageUrl: '',
            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {
            EventBus.$on('openCommentModifyModal', this.openCommentModifyModal)

        },
        beforeDestroy() {
            EventBus.$off('openCommentModifyModal')
        },
        destroyed() {},
        computed: {
        },
        methods:{
            openCommentModifyModal(comment) {
                this.commentData = comment;
                this.$nextTick(() => {
                    this.$modal.show('comment-modify-modal')
                })
            },
            openImageInfoModal(url) {
                this.imageUrl = url;
                this.$nextTick(() => {
                    this.$modal.show('image-detail-modal');
                })
            },
        },
        watch: {

        },
    }
</script>
