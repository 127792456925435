<template>
    <div>
        <div class="main_bg">
            <div class="main_image mentee"></div>
            <div class="mbc_wrap">
                <div class="main_bg_content">
                    <div class="home_logo"></div>
                    <span class="home_subtitle">우리 아이들의 꿈을 응원하는 아빠미소멘토단</span>
                </div>
            </div>
            <board-list-component :boardType="'commuHome'"></board-list-component>
        </div>

        <div class="popup_wrap">
            <div class="popup">
                <div class="popup_img">
                    <div class="popup_close"></div>
                </div>
                <div class="popup_text">하루동안 보지않기</div>
            </div>
            <div class="popup">
                <div class="popup_close"></div>
                <div class="popup_img"></div>
                <div class="popup_text">하루동안 보지않기</div>
            </div>
        </div>
        <div>
            <button class="to_top"></button>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import BoardListComponent from "@/components/board/BoardListComponent";
    export default {
        name: 'MemteeCommunity',
        mixins: [],
        components: {
            BoardListComponent
        },
        props: {
        },
        data() {
            return{

            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{

        },
        watch: {

        },
    }
</script>
