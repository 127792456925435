<template>

    <find-id-component></find-id-component>
</template>

<script>
    // @ is an alias to /src
    import FindIdComponent from "@/components/auth/FindIdComponent";
    export default {
        name: 'FindId',
        mixins: [],
        components: {
            FindIdComponent
        },
        props: {
        },
        data() {
            return{

            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
        },
        watch: {

        },
    }
</script>
