<template>

    <find-pw-component></find-pw-component>
</template>

<script>
    // @ is an alias to /src
    import FindPwComponent from "@/components/auth/FindPwComponent";
    export default {
        name: 'FindPw',
        mixins: [],
        components: {
            FindPwComponent
        },
        props: {
        },
        data() {
            return{

            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
        },
        watch: {

        },
    }
</script>
