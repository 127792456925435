<template>
    <div>
        <div>
            <check-pw-component v-if="step === 1"></check-pw-component>
            <div v-else>
                <member-update-component :memberType="memberType"></member-update-component>
            </div>
        </div>
        <update-phone-modal></update-phone-modal>
        <update-pw-modal></update-pw-modal>
    </div>
</template>

<script>
    // @ is an alias to /src
    import CheckPwComponent from "@/components/auth/CheckPwComponent";
    import MemberUpdateComponent from "@/components/member/MemberUpdateComponent";
    import UpdatePhoneModal from "@/components/modal/UpdatePhoneModal";
    import UpdatePwModal from "@/components/modal/UpdatePwModal";
    import {mapState} from "vuex";

    export default {
        name: 'Profile',
        mixins: [],
        components: {
            CheckPwComponent,
            MemberUpdateComponent,
            UpdatePhoneModal,
            UpdatePwModal
        },
        props: {
        },
        data() {
            return{
                type: '', //0: 관리자, 1: 멘토, 2: 멘티, 3: 센터
                step: 2,
                memberType: 'update' //update: 멘토 , updateMentee: 멘티
            }
        },
        beforeRouterEnter() {},
        created() {
            this.initType()
        },
        mounted() {
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            }),
            sideComponent() {
                if(this.UserInfo.mb_type === 1) {
                    return () => import(`@/components/userside/MentorUserSide`)
                }else if(this.UserInfo.mb_type === 2) {
                    return () => import(`@/components/userside/MenteeUserSide`)
                }else{
                    return null;
                }
            },

        },
        methods:{
            openModal(name) {
                this.$modal.show(name);
            },
            initType() {
                if (this.UserInfo.mb_type === 1) {
                    this.memberType = 'update'
                } else if (this.UserInfo.mb_type === 2) {
                    this.memberType = 'updateMentee'
                }
            },
            changeStep(step) {
                this.step = step;
            },
        },
        watch: {

        },
    }
</script>
