<template>
    <div>
        <component :is="sideComponent" v-if="sideComponent"  />
        <div>
            <div>
                <button class="mypage_tab" @click="changeTab('myCommentCommu')" :class="{'active' : tab === 'myCommentCommu'}">작성</button>
                <button class="mypage_tab" @click="changeTab('myLikeCommu')" :class="{'active' : tab === 'myLikeCommu'}">추천</button>
            </div>
        </div>
        <board-list-component :boardType="'myCommentCommu'" v-if="tab === 'myCommentCommu'"></board-list-component>
        <board-list-component :boardType="'myLikeCommu'" v-else-if="tab === 'myLikeCommu'"></board-list-component>
    </div>
</template>

<script>
    // @ is an alias to /src

    import BoardListComponent from "@/components/board/BoardListComponent";
    export default {
        name: 'LetterActive',
        mixins: [],
        components: {
            BoardListComponent
        },
        props: {
        },
        data() {
            return{
                type: 1, //0: 관리자, 1: 멘토, 2: 멘티, 3: 센터
                tab: 'myCommentCommu',
            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {

        },
        methods:{
            openModal(name) {
                this.$modal.show(name);
            },
            changeTab(name) {
                this.tab = '';
                this.$nextTick(() => {
                    this.tab = name;
                })
            },
        },
        watch: {

        },
    }
</script>
