<template>
    <div>
        <member-detail-component :memberType="'adminHomeDetail'" :memberKey="memberKey"></member-detail-component>
    </div>
</template>

<script>
    // @ is an alias to /src
    import MemberDetailComponent from "@/components/member/MemberDetailComponent";
    export default {
        name: 'MemberDetail',
        mixins: [],
        components: {
            MemberDetailComponent
        },
        props: {
        },
        data() {
            return{
                memberKey: this.$route.params.wr_id,
            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{

        },
        watch: {

        },
    }
</script>
