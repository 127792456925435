<template>
    <div class="mentor write">
        <div class="top">
            <div class="t-header">
                <div class="title">
                    우리 아이들의 꿈을 응원하는<br>
                    <span>아빠미소멘토단</span>
                </div>
            </div>
        </div>
        <div class="form">
        <board-write-component :boardType="'mentorWrite'"></board-write-component>
        </div>
        <div class="btn-wrap">
            <button class="write_btn full_btn mb15" @click="save">작성</button>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import BoardWriteComponent from "@/components/board/BoardWriteComponent";
    import EventBus from "@/utils/event-bus";

    export default {
        name: 'MentorCommunityWrite',
        mixins: [],
        components: {
            BoardWriteComponent,
        },
        props: {},
        data() {
            return {
                mentorList: [],
            }
        },
        beforeRouterEnter() {
        },
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {
            save() {
                EventBus.$emit('writeBoard');
            },
        },
        watch: {},
    }
</script>
