<template>
    <div>
        <board-detail-component :boardType="'letterDetail'" :boardKey="boardKey"></board-detail-component>
        <comment-modify-modal :commentData="commentData"></comment-modify-modal>
    </div>
</template>

<script>
    // @ is an alias to /src
    import BoardDetailComponent from "@/components/board/BoardDetailComponent";
    import CommentModifyModal from "@/components/modal/CommentModifyModal";
    import EventBus from "@/utils/event-bus";
    export default {
        name: 'LetterDetail',
        mixins: [],
        components: {
            BoardDetailComponent,
            CommentModifyModal
        },
        props: {},
        data() {
            return {
                boardKey: this.$route.params.wr_id,
                commentData: {},
            }
        },
        beforeRouterEnter() {
        },
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {},
        watch: {},
    }
</script>
